var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-dialog",
        {
          staticClass: "qmDialog",
          attrs: {
            visible: _vm.dialogVisible,
            title: "项目区域选择",
            width: "80%",
            top: "5vh",
            "modal-append-to-body": false,
            "close-on-click-modal": false,
          },
          on: {
            "update:visible": function ($event) {
              _vm.dialogVisible = $event
            },
          },
        },
        [
          _c(
            "el-container",
            [
              _c("CommonTree", {
                ref: "commonTreeCore",
                attrs: {
                  treeTitle: "区域分类",
                  treeData: _vm.treeData,
                  nodeKey: "id",
                  searchTitle: "typeName",
                  defaultProps: { label: "typeName" },
                  expandOnClickNode: false,
                },
                on: { getNodeClick: _vm.handleNodeClick },
              }),
              _c(
                "el-main",
                [
                  _c("head-layout", { attrs: { "head-title": "" } }),
                  _c("grid-head-layout", {
                    ref: "gridHeadLayout",
                    attrs: { "search-columns": _vm.searchColumns },
                    on: {
                      "grid-head-search": _vm.gridHeadSearch,
                      "grid-head-empty": _vm.gridHeadEmpty,
                    },
                  }),
                  _c("grid-layout", {
                    ref: "gridLayOut",
                    staticClass: "itemListGrid",
                    attrs: {
                      "table-options": _vm.tableOptions,
                      "table-data": _vm.tableData,
                      "table-loading": _vm.tableLoading,
                      "data-total": _vm.page.total,
                      page: _vm.page,
                      "grid-row-btn": _vm.gridRowBtn,
                    },
                    on: {
                      "page-current-change": _vm.handleCurrentChange,
                      "page-size-change": _vm.handleSizeChange,
                      "page-refresh-change": _vm.onLoad,
                      "row-change": _vm.rowChange,
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }