var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    [
      _c(
        "el-container",
        [
          _c(
            "el-main",
            [
              _c("head-layout", {
                attrs: {
                  "head-title": "安全技术交底",
                  "head-btn-options": _vm.headBtnOptions,
                },
                on: {
                  "head-goBack": _vm.headBack,
                  "head-submit": function ($event) {
                    return _vm.headSubmit()
                  },
                },
              }),
              _c(
                "avue-form",
                {
                  ref: "formLayout",
                  staticStyle: { padding: "10px" },
                  attrs: { option: _vm.formOptions },
                  model: {
                    value: _vm.formData,
                    callback: function ($$v) {
                      _vm.formData = $$v
                    },
                    expression: "formData",
                  },
                },
                [
                  _c(
                    "template",
                    { slot: "prjName" },
                    [
                      _c("el-input", {
                        attrs: { disabled: "", title: _vm.formData.prjName },
                        model: {
                          value: _vm.formData.prjName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "prjName", $$v)
                          },
                          expression: "formData.prjName",
                        },
                      }),
                    ],
                    1
                  ),
                  _c(
                    "template",
                    { slot: "areaName" },
                    [
                      _c("el-input", {
                        ref: "areaName",
                        attrs: {
                          disabled: _vm.type == "view" || _vm.formType != 1,
                        },
                        on: { focus: _vm.changePrjArea },
                        model: {
                          value: _vm.formData.areaName,
                          callback: function ($$v) {
                            _vm.$set(_vm.formData, "areaName", $$v)
                          },
                          expression: "formData.areaName",
                        },
                      }),
                    ],
                    1
                  ),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-container",
        [
          _c(
            "el-main",
            [
              _c("uploader", {
                ref: "uploader",
                attrs: {
                  title: "附件",
                  fileSize: 500,
                  accept:
                    ".jpg,.png,.pdf,.gif,.jpeg,.ppt,.doc,.docx,.xls,.pptx,.xlsx",
                  multiple: true,
                  fileOptions: _vm.fileOptions,
                  gridRowBtn: _vm.fileGridRowBtn,
                  disabled: _vm.type == "view" || _vm.formType > 1,
                },
                on: { getTableData: _vm.handleUpload },
              }),
            ],
            1
          ),
        ],
        1
      ),
      _c(
        "el-container",
        [
          _c(
            "el-main",
            [
              _c("head-layout", { attrs: { "head-title": "签字区" } }),
              _c(
                "avue-form",
                {
                  ref: "signLayout",
                  staticStyle: { padding: "10px" },
                  attrs: { option: _vm.signOption },
                  model: {
                    value: _vm.formData,
                    callback: function ($$v) {
                      _vm.formData = $$v
                    },
                    expression: "formData",
                  },
                },
                [
                  _c("template", { slot: "handoverFiles" }, [
                    _c(
                      "div",
                      { staticClass: "signBox" },
                      [
                        _c("div", { staticClass: "signImg" }, [
                          _c("img", {
                            attrs: { src: _vm.formData.handoverFiles, alt: "" },
                          }),
                        ]),
                        _vm.formType == 1 && _vm.type != "view"
                          ? _c("el-button", { on: { click: _vm.signQrCode } }, [
                              _vm._v("扫码签字"),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _c("template", { slot: "receiveFiles" }, [
                    _c(
                      "div",
                      { staticClass: "signBox" },
                      [
                        _c("div", { staticClass: "signImg" }, [
                          _c("img", {
                            attrs: { src: _vm.formData.receiveFiles, alt: "" },
                          }),
                        ]),
                        _vm.formType == 2 && _vm.type != "view"
                          ? _c("el-button", { on: { click: _vm.signQrCode } }, [
                              _vm._v("扫码签字"),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                  _c("template", { slot: "managerFiles" }, [
                    _c(
                      "div",
                      { staticClass: "signBox" },
                      [
                        _c("div", { staticClass: "signImg" }, [
                          _c("img", {
                            attrs: { src: _vm.formData.managerFiles, alt: "" },
                          }),
                        ]),
                        _vm.formType > 2 &&
                        _vm.nodeData.managerFlg &&
                        _vm.type != "view" &&
                        !_vm.managerFilesType
                          ? _c("el-button", { on: { click: _vm.signQrCode } }, [
                              _vm._v("扫码签字"),
                            ])
                          : _vm._e(),
                      ],
                      1
                    ),
                  ]),
                ],
                2
              ),
            ],
            1
          ),
        ],
        1
      ),
      (_vm.formType > 2 || _vm.formData.safetyFiles) &&
      _vm.formData.receiveFiles
        ? _c(
            "el-container",
            [
              _c(
                "el-main",
                [
                  _c("head-layout", { attrs: { "head-title": "执行情况" } }),
                  _c(
                    "avue-form",
                    {
                      ref: "safetyLayout",
                      staticStyle: { padding: "10px" },
                      attrs: { option: _vm.safetyOption },
                      model: {
                        value: _vm.formData,
                        callback: function ($$v) {
                          _vm.formData = $$v
                        },
                        expression: "formData",
                      },
                    },
                    [
                      _c("template", { slot: "safetyFiles" }, [
                        _c(
                          "div",
                          { staticClass: "signBox" },
                          [
                            _c("div", { staticClass: "signImg" }, [
                              _c("img", {
                                attrs: {
                                  src: _vm.formData.safetyFiles,
                                  alt: "",
                                },
                              }),
                            ]),
                            _vm.nodeData.safetyFlg &&
                            _vm.type != "view" &&
                            !_vm.safetyFilesType
                              ? _c(
                                  "el-button",
                                  { on: { click: _vm.signQrCode } },
                                  [_vm._v("扫码签字")]
                                )
                              : _vm._e(),
                          ],
                          1
                        ),
                      ]),
                    ],
                    2
                  ),
                ],
                1
              ),
            ],
            1
          )
        : _vm._e(),
      _c("prjArea", { ref: "prjArea", on: { callback: _vm.getArea } }),
      _c("prjPeople", { ref: "prjPeople", on: { callback: _vm.selectData } }),
      _c(
        "el-dialog",
        {
          attrs: {
            title: "扫描二维码",
            "modal-append-to-body": false,
            "close-on-click-modal": false,
            visible: _vm.showDialog,
            width: "40%",
            "custom-class": "qrcodeDialog",
          },
          on: {
            "update:visible": function ($event) {
              _vm.showDialog = $event
            },
            opened: _vm.getQRimg,
          },
        },
        [
          _c("div", { staticStyle: { "text-align": "center" } }, [
            _c("div", { staticClass: "qrcode-img" }, [
              _c("div", { ref: "qrCode", attrs: { id: "qrcode" } }),
            ]),
            _c("p", { staticClass: "tip" }, [_vm._v("打开移动端扫码签字")]),
          ]),
        ]
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }