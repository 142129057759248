var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "el-dialog",
    {
      staticClass: "qmDialog selectPeopleByCurrentPrjoect",
      attrs: {
        title: _vm.title,
        "modal-append-to-body": false,
        "close-on-click-modal": false,
        visible: _vm.showDialog,
        top: "5vh",
        width: "70%",
      },
      on: {
        "update:visible": function ($event) {
          _vm.showDialog = $event
        },
        close: _vm.closeDialog,
      },
    },
    [
      _c(
        "el-tabs",
        {
          on: { "tab-click": _vm.handleTabClick },
          model: {
            value: _vm.activeName,
            callback: function ($$v) {
              _vm.activeName = $$v
            },
            expression: "activeName",
          },
        },
        [
          _c(
            "el-tab-pane",
            { attrs: { label: "组织&人员", name: "organizationPersonnel" } },
            [
              _c(
                "el-container",
                [
                  _c("CommonTree", {
                    ref: "commonTree",
                    attrs: {
                      treeTitle: "项目架构",
                      searchTitle: _vm.searchOrTitle,
                      treeData: _vm.organizationPersonnelTreeData,
                      isShowdig: false,
                      defaultProps: _vm.organizationPersonnelDefaultProps,
                      treeExpand: true,
                      "node-key": "id",
                    },
                    on: {
                      getNodeClick: _vm.organizationPersonnelTreeNodeClick,
                    },
                  }),
                  _c(
                    "el-main",
                    [
                      _c("grid-layout", {
                        ref: "gridLayOut",
                        attrs: {
                          "table-options": _vm.tableOption,
                          "table-data": _vm.organizationPersonnelTableData,
                          "table-loading": _vm.tableLoading,
                        },
                        on: {
                          "page-current-change": _vm.getPrjstructureuserList,
                          "page-size-change": _vm.getPrjstructureuserList,
                        },
                        scopedSlots: _vm._u([
                          {
                            key: "birthday",
                            fn: function ({ row }) {
                              return [
                                _c("span", [
                                  _vm._v(
                                    "\n                      " +
                                      _vm._s(
                                        row.birthday && row.birthday != ""
                                          ? _vm.getAge(row.birthday)
                                          : ""
                                      ) +
                                      "\n                  "
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "customBtn",
                            fn: function ({ row }) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { margin: "0 3px" },
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.rowSelect(row)
                                      },
                                    },
                                  },
                                  [_vm._v("选择")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
          _c(
            "el-tab-pane",
            { attrs: { label: "相关方人员", name: "thirdPartyPersonnel" } },
            [
              _c(
                "el-container",
                [
                  _c("CommonTree", {
                    ref: "commonTreeUser",
                    attrs: {
                      treeTitle: "相关方公司",
                      searchTitle: _vm.searchOrThirdParty,
                      treeData: _vm.userThirdPartyOrganizationTableData,
                      defaultProps: _vm.thirdPartyPersonnelDefaultProps,
                      isShowdig: false,
                      showCheckbox: false,
                      treeExpand: false,
                      "node-key": "id",
                    },
                    on: { getNodeClick: _vm.thirdPartyPersonnelTreeNodeClick },
                  }),
                  _c(
                    "el-main",
                    [
                      _c("grid-head-layout", {
                        ref: "thirdPartyPersonnelGridHeadLayout",
                        attrs: {
                          "search-columns": _vm.corePersonnelSearchColumns,
                        },
                        on: {
                          "grid-head-search":
                            _vm.thirdPartyPersonnelGridHeadSearch,
                          "grid-head-empty":
                            _vm.thirdPartyPersonnelGridHeadEmpty,
                        },
                      }),
                      _c("grid-layout", {
                        ref: "thirdGridLayOut",
                        staticClass: "itemListGridNoPage",
                        attrs: {
                          "table-options": _vm.thirdPartyPersonnelTableOption,
                          "table-data": _vm.thirdPartyPersonnelTableData,
                          "table-loading": _vm.tableLoading,
                        },
                        on: { "gird-handle-select-click": _vm.selectionChange },
                        scopedSlots: _vm._u([
                          {
                            key: "birthday",
                            fn: function ({ row }) {
                              return [
                                _c("span", { style: _vm.itemObject(row) }, [
                                  _vm._v(
                                    "\n                  " +
                                      _vm._s(
                                        row.birthday && row.birthday != ""
                                          ? _vm.getAge(row.birthday)
                                          : ""
                                      ) +
                                      "\n                "
                                  ),
                                ]),
                              ]
                            },
                          },
                          {
                            key: "customBtn",
                            fn: function ({ row }) {
                              return [
                                _c(
                                  "el-button",
                                  {
                                    staticStyle: { margin: "0 3px" },
                                    attrs: { type: "text", size: "small" },
                                    on: {
                                      click: function ($event) {
                                        return _vm.rowSelect(row)
                                      },
                                    },
                                  },
                                  [_vm._v("选择")]
                                ),
                              ]
                            },
                          },
                        ]),
                      }),
                    ],
                    1
                  ),
                ],
                1
              ),
            ],
            1
          ),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }